<template>
  <div class="text-center">
    <v-overlay>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {},
  components: {},
  data() {
    return {};
  },
};
</script>
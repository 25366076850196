<template>
  <div>

    <v-app-bar  color="white"  fixed >
      <img
        alt="Vue logo"
        src="../assets/logo.png"
        height="60"
        contain
      />

      <!-- <v-toolbar-title>Page title</v-toolbar-title>-->

      <v-spacer></v-spacer>

    </v-app-bar>


    <!--<v-app-bar
      absolute
      color="white"
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
      class="mt-5"
>
<v-container>
      <img
        alt="Vue logo"
        src="../assets/logo.png"
        height="60"
        contain
      />

      </v-container> 
    </v-app-bar>
 
      <div class="purple darken-2 text-left">
    <span class="white--text">Lorem ipsum</span>
  </div>-->
  </div>

  
</template>



<script>
// @ is an alias to /src


export default {
  name: "Bar",
  data: () => ({
     
  }),

  
};
</script>
<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<style>
.v-avatar {
border:1px solid #ddd !important;
}
.cart-btn
{
  width: 90%;
}

</style>
<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

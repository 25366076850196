import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;


import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)


//Bar component
import Bar from "./components/Bar";
Vue.component("Bar", Bar);

//Footer component
import FooterCommon from "./components/FooterCommon";
Vue.component("footerCommon", FooterCommon);

//Loading component
import Loading from "./components/Loading";
Vue.component("loading", Loading);

import MarqueeText from 'vue-marquee-text-component'
Vue.component('marquee-text', MarqueeText)

import VueEasyLightbox from 'vue-easy-lightbox'


new Vue({
  router,
  store,
  vuetify,
  VueEasyLightbox,
  render: (h) => h(App),
}).$mount("#app");
